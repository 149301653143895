<template>
  <li class="znav-item">
    <ZLink
      v-bind="$attrs"
      class="znav-link"
      :class="[linkClasses]"
    >
      <slot />
    </ZLink>
  </li>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    linkClasses: {
      type: [Array, String],
      default: '',
    },
  },
}
</script>
